<template>
  <div v-html="getHtml" class="word">
    </div>
 
</template>
<script>
import config from "@/common/config/common/icpword.config.js";
export default {
  data() {
    return {
      config,
      id:this.$route.query.id
    };
  },
  watch:{
    $route(e){
      this.id=e.query.id
    }
  },
  computed:{
      getHtml(){
        if(this.id){
          const data=config.config[this.id]
          var html=''
          if(!data.html){
           
            html=config.config[data.refId-1].html
          }
          else{
            html=data.html
          }
           return html
        }
        else{
          return ''
        }
        
      }
  },
  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.word {
  max-width: 1024px;
  margin: auto;
}
.my-component {
  width: 100%;
  height: 90vh;
  border: 1px solid #000;
}
</style>